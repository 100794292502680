//---------------------------------------------------------
// Global SCSS File
//---------------------------------------------------------

//---------------------------------------------------------
// FONTS
//---------------------------------------------------------
@font-face {
  font-family: "Museo Sans";
  src: url('/assets/font/MuseoSans-500.woff2');
  font-weight: 500;
}

@font-face {
  font-family: "Museo Sans";
  src: url('/assets/font/MuseoSans-700.woff2');
  font-weight: 700;
}

@font-face {
  font-family: "Museo Sans";
  src: url('/assets/font/MuseoSans-900.woff2');
  font-weight: 900;
}

@import "~@ng-select/ng-select/themes/default.theme.css";

//@font-face {
//  font-family: "JetBrains Mono";
//  src: url('/assets/font/JetBrainsMono-Regular.woff2');
//  font-weight: 500;
//}

:root {
  //---------------------------------------------------------
  // CSS VARIABLES: COLORS
  //---------------------------------------------------------
  --primary_color: #ff8d52;

  --primary_color_tint: #ff9e6c;
  --primary_color_tint_2: #FE9761;
  --primary_color_tint_3: #FFA653;
  --primary_color_tint_4: rgba(255, 141, 82, 0.9);

  --primary_color_shade: #FE7A46;
  --primary_color_shade_2: #ee801c;
  --primary_color_shade_3: #D55C07;
  --primary_color_shade_4: #F2A665;

  --text_color: #63585C;
  --text_grey_color: #8D8489;
  --text-grey_color_2: #504F4F;

  --background_color: #F7F7F7;
  --primary_light_background_color: #f5eae0;

  // user UI messages coloring
  --error_color: #F56E80;
  --valid_color_light: #95bf88;
  --valid_color: #0B9645;

  --link_color: #70B3E2;

  --darker-grey: #f1f1f1;
  --light_grey: #F4F7F9;
  --almost_white: #F6F6F6;
  --very_light_grey: #EDEDED;
  --middle_light_grey: #fbfbfb;
  --very_light_grey_2: #fBfBfB;
  --grey: #CECECE;
  --light_black: #333333;

  // used for gradients
  --brand-gradient-color-start: #FFC154;
  --brand-gradient-color-end: var(--primary_color);

  --light-blue: #A5D3F3;
  --text-dark-blue: #053E75;

  // measurement quality colors
  --measurement_color_green: #0B9645;
  --measurement_color_light_green: #1CC413;
  --measurement_color_olive: #A9B207;
  --measurement_color_orange: #F4B710;
  --measurement_color_red: #F56E80;

  // measurement type colors
  --set_measurement_color_blue: #81c1ef;
  --set_measurement_color_orange: #ff8f56;
  --set_measurement_color_purple: #9c7ee2;
  --set_measurement_color_black: black;
  --set_measurement_color_red: #8b0000;

  // note type colors
  --note-image-color: #1F6809;
  --note-image-color-light: #C1E4B6;

  --note-text-color: #094D7C;
  --note-text-color-light: #A5D3F3;

  --note-audio-color: #3514B0;
  --note-audio-color-shade: #280DB0;
  --note-audio-color-light: #C3A9FF;

  --challenge-color-blue: #1D70FF;
  --challenge-color-light-blue: #BBD4FF;
  --challenge-color-dark-blue: #0C377F;
  --challenge-color-grey: #B6C3D8;

  //---------------------------------------------------------
  // Global ui elements
  //---------------------------------------------------------
  --main-ui-clamp-width: min(100vw, 100rem);

  //---------------------------------------------------------
  // CSS VARIABLES: GRADIENTS
  //---------------------------------------------------------
  --brand-gradient: linear-gradient(346.84deg, var(--brand-gradient-color-start) 0%, var(--brand-gradient-color-end) 100%);
  --brand-gradient-light: linear-gradient(346.84deg, rgba(255, 193, 84, 0.21) 0%, rgba(255, 141, 82, 0.21) 100%);
  --chat-bubble-gradient: linear-gradient(184.76deg, var(--brand-gradient-color-start) 0%, var(--primary_color) 100%);

  //background: linear-gradient(346.84deg, #FFC154 0%, #FF8D52 100%);



  //---------------------------------------------------------
  // CSS VARIABLES: BORDERS
  //---------------------------------------------------------
  --lighter-gray-border: 1px solid #f2f2f2;
  --light-gray-border: 1px solid #EDEDED;
  --white-border: 1px solid #FFFFFF;

  //---------------------------------------------------------
  // CSS VARIABLES: TYPOGRAPHY
  //---------------------------------------------------------
  --font-family-primary: "Museo Sans", sans-serif;
  //--font-family-debug: "JetBrains Mono", sans-serif;

  //---------------------------------------------------------
  // TEXT SHADOWS
  //---------------------------------------------------------
  --base-orange-text-shadow: 0.15rem 0.15rem 0.3rem var(--primary_color_shade_3);
  --base-orange-text-shadow-darker: 0.15rem 0.15rem 0.3rem #C1630E;
}

@import "mixins";
@import "functions";
@import "modal";

//---------------------------------------------------------
// MAIN HTML ELEMENTS
//---------------------------------------------------------
html {
  box-sizing: border-box;
  font-size: 10px;
  height: 100%;
  overflow-x: hidden;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  min-height: 100%;
  width: 100%;
  margin: 0;
  font-size: 1.6rem;
  font-family: var(--font-family-primary);
  color: var(--text_color);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  text-rendering: optimizeLegibility;
}

//---------------------------------------------------------
// HEADER STYLING
//---------------------------------------------------------
h1, h2 {
  font-weight: 500;
}
h2 {
  font-size: 2.8rem;
}
h3, h4 {
  font-weight: 700;
}

//---------------------------------------------------------
// OTHER GLOBAL STYLING
//---------------------------------------------------------
p {
  margin-top: 0;
  line-height: 1.8rem;
}

a {
  color: var(--text_color);
}

.bold {
  font-weight: 700;
}

.extra-bold {
  font-weight: 900;
}

.display-none {
  display: none !important;
}

.lowercase {
  text-transform: lowercase;
}

.hidden {
  visibility: hidden;
}

.add-connection-button {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  border: 0.2rem var(--text_color) solid;
  display: inline-block;
  vertical-align: middle;

  .add-connection-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.text-link {
  color: var(--link_color);
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
}

.error-message {
  color: var(--error_color);
  font-size: 1.4rem
}

.success-message {
  color: var(--valid_color);
  font-size: 1.4rem;
}

.debug-message {
  font-size: 1.2rem;
  color: purple;
  font-family: var(--font-family-debug);
}

.icon-button-base {
  width: 2.8rem;
  height: 2.8rem;
  border: 0.15rem var(--text_color) solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  .chat-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.new-message-indicator {
  width: 0.78rem;
  height: 0.78rem;
  background-color: #70B3E2;
  position: absolute;
  border-radius: 50%;
  right: 0.5rem;
  top: 0.6rem;
  border: solid #FFFFFF 0.05rem;
}

//---------------------------------------------------------
// REMOVE ARROWS INPUT TYPE NUMBER
//---------------------------------------------------------
::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

//---------------------------------------------------------
// SCROLLBAR STYLING
//---------------------------------------------------------
* {
  ::-webkit-scrollbar {
    width: 0.6rem;
    //height: 0.6rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.2);
    @include border-radius(0.4rem);
  }
  ::-webkit-scrollbar-track {
    background: hsla(0,0%,100%,.1);
  }

  // firefox scrollbar styling
  scrollbar-color: rgba(0,0,0,.2) hsla(0,0%,100%,.1);
  scrollbar-width: thin;
}


//necessary ng-select styling when dropdown is appended to body
::ng-deep {
  .ng-dropdown-panel.ng-select-bottom {
    border-radius: 0.2rem 2rem 2rem 2rem;

    .ng-dropdown-panel-items .ng-option:last-child {
      border-radius: 0 0 2rem 2rem;
    }
  }

  .filter-option-label,
  .ng-option-label{
    font-size: 1.5rem;
    font-weight: bold;
  }
}
