//---------------------------------------------------------
// MENU SIZE VARIABLES
//---------------------------------------------------------
$hamburger-menu-width: 30rem;
$nav-bottom-menu-height: 8rem;
$nav-bottom-menu-height-small-screen: 6.5rem;

//---------------------------------------------------------
// DASHBOARD BLOCK SIZE VARIABLES
//---------------------------------------------------------
$dashboard-item-height: 10rem;
$dashboard-item-height-large: 12.5rem;
$dashboard-item-width-small: 11rem;
$dashboard-item-width-big: 18rem;

//---------------------------------------------------------
// DASHBOARD CHAT SIDE BAR WIDTH
//---------------------------------------------------------
$chat-avatar-sidebar-width: 10.4rem;

//---------------------------------------------------------
// MEDIA QUERY SCREEN SIZES VARIABLES
//---------------------------------------------------------
$screen-min-widescreen-width: 1000px;

/* starting point for XS */
$screen-xs: 320px !default;
/* starting point for SM */
$screen-sm: 576px !default;
$screen-sm-minus: 575px !default;
/* starting point for MD */
$screen-md: 768px !default;
$screen-md-minus: 767px !default;
/* starting point for LG */
$screen-lg: 992px !default;
$screen-lg-minus: 991px !default;
/* starting point for XL */
$screen-xl: 1200px !default;
$screen-xl-minus: 1199px !default;
/* starting point for XXL */
$screen-xxl: 1600px !default;
$screen-xxl-minus: 1599px !default;
/* starting point for TV */
$screen-tv: 2560px !default;
$screen-tv-minus: 2599px !default;

$max-screen-width-wide-screen: 2000px;
